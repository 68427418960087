a.nav-link {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

a.nav-link.active,
div.nav-item.active {
  background-color: white;
}
