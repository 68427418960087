.loading-overlay-fixed {
  .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #ffffff;
  z-index: 10;
}

.loading-overlay {
  .spinner {
    position: relative;
    top: 50%;
    left: 50%;
  }
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #ffffff;
}
