.footer {
  width: 100%;
  padding: 10px;
  flex-shrink: 0;
  background-color: #f8f9fa;
}

.footer_inner {
  .text {
    font-size: small;
  }
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column wrap;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
}
