.login-page {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.login-title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  padding: 40px;
}

.login {
  width: 600px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 20px;

  .button-group {
    .button {
      margin: 5px;
    }
    display: flex;
    justify-content: center;
  }
}
