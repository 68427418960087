.weatherstationmanager {
  .pageItem {
    margin: 10px;
  }
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: stretch;
}

.tableContainer {
  .tableBox {
    tr {
      height: 35px;
    }
    height: 300px;
    overflow-y: scroll;
  }
  padding: 5px;
}

.cardMargin {
  margin-bottom: 15px;
}

.detailItem {
  .title {
    font-weight: 200;
  }
  .content {
    font-weight: 400;
  }
  margin: -7px;
  font-size: small;
}

