html,
body,
#root {
  height: 100%;
  background-color: #f8f9fa;
}
#root {
  display: flex;
  flex-direction: column;
}
#page-content {
  flex: 1 0 auto;
  background-color: white;
}
#footer {
  flex-shrink: 0;
}
