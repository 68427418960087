.header {
  width: 100%;
  height: 50px;
}

.header_inner {
  height: 50px;
  max-width: 990px;
  margin: 0 auto;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
}

.header-img {
  width: 150px;
}
